/**
 * Created by nick on 1/20/2020.
 */
import React from "react"
import { Link } from "gatsby"
import SiteInfo from "../components/siteinformation"

import MainLayout from "../components/mainlayout"
import SEO from "../components/seo"

const Contact = () => (
    <MainLayout>

        <section class="recipes-section spad pt-0">
            <div class="container">
                <SEO title="terms" /><div>
                <h2>Contact US</h2>
                You can email us at: {getSiteEmail()}
            </div>
            </div>
        </section>
    </MainLayout>
)

export default Contact

function getSiteEmail() {
    var site = new SiteInfo();

    return site.getSiteEmail();
}



